import React, { useState } from "react";
import AlternativeNavbar from "../components/AlternativeNavbar";
import Footer from "../components/Footer";
import pic1 from "../assets/pic1.png";
import pic2 from "../assets/pic2.png";
import pic3 from "../assets/pic3.png";
import pic4 from "../assets/pic4.png";
import AccordionItem from "../components/AccordionItem";

function Faq() {
  const wjccc = (
    <div>
      <p className="tcmttnormal tcppec">Why this programme?</p>
      <p className="tcmttnormal">
        The Clean Creator Community programme is a one-of-a-kind affiliate
        program for creators. What makes it one-of-a-kind? Our purpose.
      </p>
      <p className="tcmttnormal">
        We are proud of this community’s commitment to championing the clean
        lifestyle and beauty cultural movement, certifying all products within
        the programme are sustainable, science-backed and high-performance.
      </p>
      <p className="tcmttnormal">
        Our affiliate program is created by Amyris, a leading biotech company
        dedicated to creating sustainable solutions for a better world. Using
        our extensive knowledge and expertise, we are committed to partnering
        with nano and micro creators who share our passion for community,
        sustainability and innovation.
      </p>
    </div>
  );

  const appc = (
    <div>
      <p className="tcmttnormal tcppec">How do I apply?</p>
      <p className="tcmttnormal">
        Select <strong>Apply Now</strong> to start your application. <br />
        Next, fill out the following three short sections:
      </p>
      <ol className="socialul">
        <li>Basic info: creating an account for your application tracking.</li>
        <li>
          Connect channels: Connecting at least 1 of your social/digital
          channels, for the team to review your application.
        </li>
        <li>
          About You: Let us know more about you and your product and content
          preferences.
        </li>
        <li>
          Welcome Pack: We believe in authentic reviews. Therefore we want you
          to get started by getting your hands into your own selection of our
          products.
        </li>
      </ol>
      <img src={pic1} alt="" />
      <p className="tcmttnormal">And your application is submitted!!</p>
      <p className="tcmttnormal tcppec">What is a welcome pack?</p>
      <p className="tcmttnormal">
        To kick off your onboarding to the programme, we give all approved
        affiliates a welcome pack, so you can experience the products firsthand.
      </p>
      <p className="tcmttnormal">
        The welcome pack includes a bespoke selection, done by you, of our
        science-backed and sustainable products, giving you a taste of what we
        offer.
      </p>
      <p className="tcmttnormal">
        We believe that by experiencing our products, our affiliates can better
        promote them and share their benefits with their audience, in the most
        authentic way.
      </p>
      <p className="tcmttnormal tcppec">
        Why is my application being reviewed?
      </p>
      <p className="tcmttnormal">
        Once submitted, we review your application to make sure our mission fits
        with your content purpose and style.
      </p>
      <p className="tcmttnormal">
        We review all applications within 24-48 hrs and confirm your eligibility
        to become a part of the Clean Creator Community. You will receive an
        email confirmation once you’re approved, with immediate access to the
        platform and to book your onboarding session with one of our beauty
        advisors.
      </p>

      <p className="tcmttnormal tcppec">What is the approval criteria?</p>
      <p className="tcmttnormal">
        During the review and approval process, our team will be making sure
        you:
      </p>
      <ol className="socialul">
        <li>
          Have a public account with a minimum of 2,000+ followers on at least
          one of your socials..
        </li>
        <li>Have a following:followers ratio of no more than 1:3</li>
        <li>
          Have a consistent feed, showcasing you are an active creator with a
          clear message and value for your audience.
        </li>
        <li>Are over 18 years old.</li>
        <li>
          Your profile showcases your commitment to and interest in championing
          a clean and sustainable lifestyle.
        </li>
        <li>
          Your engagement with your audience: We want to make sure you are
          actively nurturing your relationship with your community.
        </li>
      </ol>
      <p className="tcmttnormal tcppec">
        Why should I connect my socials?
      </p>
      <p className="tcmttnormal">
        When linking your social accounts to our portal, we’ll request for your
        authorization to access your public social data, in order to verify that
        you are the actual owner. Connecting at least one channel is mandatory.
      </p>
      <p className="tcmttnormal">
        Once linked, we do not have access to any private information. To find
        out more about, please consult our data privacy policy <a href = "/privacy-notice">here</a>
      </p>
    </div>
  );

  const al = (
    <div>
      <p className="tcmttnormal tcppec">
        How can I generate links/code for the products I want to promote?
      </p>
      <p className="tcmttnormal">
        We want to make sure you have all options available to share your
        product recommendation with your audience, through the content formats
        that best work for you. Therefore, you can create either an affiliate
        link or a bespoke code.{" "}
      </p>
      <p className="tcmttnormal">
        You’ll be able to discover the available products, filtering by brand or
        category. Each product will have a button for the link/code to be
        generated in seconds.{" "}
      </p>
      <p className="tcmttnormal">
        Through the results dashboard, you’ll be able to see the performance
        each link/code has generated and how much commission each of them has
        attributed you.
      </p>
    </div>
  );

  const cae = (
    <div>
      <p className="tcmttnormal tcppec">
        How can I check how much I have earned until now?
      </p>
      <p className="tcmttnormal">
        You will have access to a realtime dashboard, where all your data
        performance will be shown. You’ll be able to track:
      </p>
      <ol className="socialul">
        <li>The number of products you sold</li>
        <li>The commissions you’ve made</li>
        <li>Your total earnings</li>
        <li>Your channels performance</li>
        <li>
          The tier level you are at, with each brand, and the next milestone to
          achieve to raise your partnership level to unblock more benefits and
          commissions.
        </li>
      </ol>
      <p className="tcmttnormal tcppec">
        How much commission can I make per sale?
      </p>
      <p className="tcmttnormal">
        In the Clean Creator Community programme, we work on the basis of
        commission schemes per brand, starting on average of 15% per sale.
      </p>
      <p className="tcmttnormal">
        You have the opportunity to upgrade your partnership status from one
        tier to the other, based on your performance and accomplishment of the
        milestones set per brand.
      </p>
      <p className="tcmttnormal">
        For example: you can go from Tier 1 to Tier 2 level with a brand, once
        you’ve accomplished the milestone to unlock Tier 2. This milestone could
        be reaching your first 20 units sales.
      </p>
      <p className="tcmttnormal">
        The power is in your hands to complete each milestone to raise your
        partnership status and unlock a higher commission and more benefits.
      </p>
      <p className="tcmttnormal">
        We manage our brands highest commission rates, as in this programme you
        are partnering directly with the brands. There are no retailers or third
        parties involved.
      </p>
      <p className="tcmttnormal tcppec">
        How can i incentivize my audience to complete a purchase:
      </p>
      <p className="tcmttnormal">
        In the Clean Creator Community programme we want to set up our partners
        for success from day one.{" "}
      </p>
      <p className="tcmttnormal">
        That is why, the commission scheme per brand, also includes a percentage
        of discount to share and incentivise your audience to complete a
        purchase using your affiliate link or code.
      </p>
      <p className="tcmttnormal">
        The percentage of discount will vary per brand and per tier level. These
        discounts are exclusive to our affiliate partners, through our
        programme.
      </p>
      <p className="tcmttnormal tcppec">How are the commissions calculated:</p>
      <p className="tcmttnormal">
        In the Clean Creator Community we don’t limit your commission to only
        the product you’ve promoted, but we reward you over the total order
        value the final user has generated. Furthermore, we put your earning
        growth potential in your hands with our commission scheme per brand. As
        you complete the different milestones per tier, you’ll be able to
        increase your commission per sale.
      </p>
      <p className="tcmttnormal">
        The total commission earned is directly calculated over the final order
        value of the full order achieved through your affiliate link/code, after
        the promo code discount is applied.{" "}
      </p>
      <p className="tcmttnormal tcppec">What is a Cookie Window?</p>
      <p className="tcmttnormal">
        Cookie Window is the metric that indicates how long a customer has to
        make a purchase before they're no longer counted as a commission sale.
      </p>
      <p className="tcmttnormal">
        For example: If the cookie window is 30 days, your customer has 30 days
        to purchase the product through your link before it expires.
      </p>
      <p className="tcmttnormal">
      Across the Clean Creator Community programme, all of our brands manage a 30 day cookie window.
      </p>
      <p className="tcmttnormal tcppec">Payments:</p>
      <p className="tcmttnormal tcppec">When can I cashout my commision?</p>
      <p className="tcmttnormal">
        An influencer will start seeing commissions once the order placed has
        passed the 30 day return policy of the brand, i.e., the commissions will
        be available to cash out as soon as this period has lapsed.
      </p>
      <p className="tcmttnormal">
        The cashout balance will be available once you reach a minimum of £25 in
        commission. You will have the ability to request payment of this balance
        from your dashboard and receive the payment in the next 7 working dates.
      </p>
      <p className="tcmttnormal tcppec">
        How will I receive my commission payments?
      </p>
      <p className="tcmttnormal">
        The first step to set up your commission payments will be to add your
        bank account details on your profile. This information will only be
        visible to our financial team.{" "}
      </p>
      <p className="tcmttnormal">
        In your analytics dashboard you will be able to see your total earnings
        and the available cash out balance you have.{" "}
      </p>
      <p className="tcmttnormal">
        The cashout balance will be available once you reach a minimum of £25 in
        commission. You will have the ability to request payment of this balance
        from your dashboard and receive the payment in the next 7 working dates.
      </p>
    </div>
  );

  const eaa = (
    <div>
      <p className="tcmttnormal tcppec">
        How can I register for an upcoming event?
      </p>
      <p className="tcmttnormal">
        Once you are logged In, go to Community Hub → Events and Experiences and
        look for all the upcoming events.{" "}
      </p>
      <p className="tcmttnormal">
        You can click on RSVP or Book your spot buttons, to book your place. You
        will receive a confirmation email with the event details.
      </p>
      <img src={pic2} alt="" />
      <p className="tcmttnormal tcppec">
        How can I book my 1-on-1 sessions with the beauty Advisors?
      </p>
      <p className="tcmttnormal">
        Once you are logged In, go to Community Hub → Virtual Sessions and
        select the topic, brand and date that works for you. All available time
        slots will appear.{" "}
      </p>
      <p className="tcmttnormal">
        Please select the option that best suits you and click on confirm
        booking.{" "}
      </p>
      <p className="tcmttnormal">
        You will receive a confirmation email with the call details.
      </p>
      <img src={pic3} alt="" />
      <p className="tcmttnormal tcppec">What if I have any other queries?</p>
      <p className="tcmttnormal">
        You can submit a ticket to our support team, including all relevant
        information that will help us give answer to your request. <br />
        You’ll be able to set up the priority level of your request, so our team
        can assist you properly.
      </p>
      <p className="tcmttnormal">
        To access the Help centre, go to Community Hub → Help
        Centre and submit form.{" "}
      </p>
      <img src={pic4} alt="" />
    </div>
  );
  const accordionlist = [
    {
      id: 1,
      title: "Why join Clean Creator Community Programme",
      view: wjccc,
    },
    {
      id: 2,
      title: "Application",
      view: appc,
    },
    {
      id: 3,
      title: "Affilliate Links",
      view: al,
    },
    {
      id: 4,
      title: "Commissions and Earnings",
      view: cae,
    },
    {
      id: 5,
      title: "Events and Advisors",
      view: eaa,
    },
  ];

  const [clicked, setClicked] = useState("0");
  const handleToggle = (index) => {
    if (clicked === index) {
     return setClicked("0");
    }
    setClicked(index);
   };
  return (
    <>
      <AlternativeNavbar />
      <div className="faqlayout__cnt">
        <div className="faqlayout-top">
          <p className="faqtitle">Frequently Asked Questions</p>
          <p className="faqparagraph">
            Take a look at the common questions we receive from regular users,
            and their answers. The solution to your issue might just be there.
          </p>
        </div>
        <div className="faqbody">
          <ul className="accordion">
            {accordionlist.map((faq, index) => (
              <AccordionItem  onToggle={() => handleToggle(index)} key={index} faq={faq} active={clicked === index} />
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Faq;
