import howitworks01 from "../assets/howitworks01.svg";
import howitworks02 from "../assets/howitworks02.svg";
import howitworks03 from "../assets/howitworks03.svg";
import instagramicon from "../assets/instagramicon.svg";
import roseProduct from "../assets/roseproduct.png";
import roselogo from "../assets/roselogo.png";
import biossanceproduct from "../assets/biossanceproduct.png";
import biossancelogo from "../assets/biossancelogo.jpg";
import jvnproduct from "../assets/jvnproduct.png";
import jvnlogo from "../assets/jvnlogo.png";

export const navigationLinks = [
  {
    id: "whatitsabout",
    title: "WHAT IT'S ABOUT",
  },
  {
    id: "howitworks",
    title: "HOW IT WORKS",
  },
  {
    id: "ambassadors",
    title: "BRAND FOUNDERS",
  },
  {
    id: "brands",
    title: "BRANDS",
  },
];

export const extraLinks = [
  // {
  //   linkAddress: `${process.env.REACT_APP_CREATOR_APP_URL}login`,
  //   title: "LOGIN",
  // },
  // {
  //   linkAddress: `${process.env.REACT_APP_CREATOR_APP_URL}register`,
  //   title: "APPLY NOW",
  // },
];

export const howitworks = [
  {
    id: "howitworks-1",
    icon: howitworks01,
    title: "EARN NEW INCOME",
    content1: "Monetise your content & earn over thousands in commissions.",
    content2:
      "With a free bespoke welcome pack, experience the products + Free samples with every new order.",
    content3:
      "Upgrade your partner status to raise commission & enjoy exclusive deals to share with your audience.",
  },
  {
    id: "howitworks-2",
    icon: howitworks02,
    title: "RAISE YOUR IMPACT",
    content1: "Access tools to grow your audience & raise your engagement.",
    content2: "Real-time content & sales performance tracking.",
    content3:
      "Be spotlighted across the brands socials as a key community partner.",
  },
  {
    id: "howitworks-3",
    icon: howitworks03,
    title: "JOIN THE MOVEMENT",
    content1: "Inspire consumers to make better choices without compromise.",
    content2:
      "Collaborate with peer clean lifestyle & beauty enthusiasts, nano and micro creators accross the world.",
    content3:
      "Access exclusive community events & experiences, experts guidance from brands advisors and ambassadors.",
  },
];

export const footerLinks = [
  {
    linkaddress: "about",
    name: "ABOUT US",
  },
  {
    linkaddress: "faq",
    name: "FAQ",
  },
  {
    linkaddress: "terms-and-conditions",
    name: "TERMS AND CONDITION",
  },
  // {
  //   linkaddress: "terms-of-use",
  //   name: "TERMS OF USE",
  // },
  {
    linkaddress: "privacy-notice",
    name: "PRIVACY POLICY",
  },
  {
    linkaddress: "info@cleancreatorcommunity.com",
    name: "CONTACT US",
  },
  {
    
  }
]; 

export const socialMediaLinks = [
  {
    id: "social-1",
    icon: instagramicon,
    link: "https://www.instagram.com/",
  },
];

export const brandsimages = [
  {
    id: "brand-1",
    product: roseProduct,
    logo: roselogo,
  },
  {
    id: "brand-2",
    product: biossanceproduct,
    logo: biossancelogo,
  },
  {
    id: "brand-3",
    product: jvnproduct,
    logo: jvnlogo,
  },
];
