import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import Modal from "react-modal";
import "./index.css";
import router from "./App.jsx";
import ModalCookies from "./components/ModalCookies";
Modal.setAppElement('#root')

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
        <RouterProvider router={router} />
  
      
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
