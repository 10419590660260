import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import logo from "../assets/Logo.svg";
import logoPurple from "../assets/Logopurple.png";
import close from "../assets/closeiconwhite.svg";
import menu from "../assets/menuiconwhite.svg";
import menupurple from "../assets/menuiconpurple.svg";
import { extraLinks } from "../constants";

const AlternativeNavbar = () => {
  const [toggle, setToggle] = useState(false);
  let navigate = useNavigate();

  return (
    <div className="thenav">
      <nav className="w-full navbarwhite flex items-center justify-between navbar">
        {/* <img
        style={{cursor: "pointer"}}
          src={logoPurple}
          alt="armris"
          className="navlogo w-[150px] h-[80px] object-contain mt-[20px] ml-[20px]  mb-[20px]"
          onClick={() => navigate("/")}
        /> */}
        <ul
          className={
            "list-none mt-[-20px] md:flex hidden justify-end items-center mt-[-20px]"
          }
        >
          {extraLinks.map((nav, index) => (
            <li
              key={nav.title}
              className={` md:flex hidden cursor-pointer mt-[20px] text-[14px] text-black ${
                index === extraLinks.length - 1
                  ? "mr-[20px]"
                  : "mr-[30px] ml-[30px]"
              } ${nav.title === "APPLY NOW" ? "auth-btn2" : "logauthh-btn"}`}
            >
              <a href={`${nav.linkAddress}`}>{nav.title}</a>
            </li>
          ))}
        </ul>
        <div className="md:hidden flex flex-1 justify-end items-center">
          {/* <img
            src={toggle ? close : menupurple}
            alt="menu"
            className="cursor-pointer w-[36px] h-[36px] mr-[30px]"
            onClick={() => setToggle(!toggle)}
          /> */}
        </div>
      </nav>

      {!toggle ? (
        <></>
      ) : (
        <div className="md:hidden togglenavbar">
          <div className="togglenavbar-top">
            <img src={logo} alt="" style={{cursor: "pointer"}} className="navlogo  w-[150px] h-[80px] " onClick={() => navigate("/")} />
            <img
              src={toggle ? close : menu}
              alt="menu"
              className="cursor-pointer w-[36px] h-[36px] "
              onClick={() => setToggle(!toggle)}
            />
          </div>
          <div className="navlinks">
            <ul className="">
              {extraLinks.map((nav, index) => (
                <li className={`hover:underline`}>
                  <a href={`${nav.linkAddress}`}>{nav.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlternativeNavbar;
