import React from "react";
import { useRouteError } from "react-router-dom";
import AlternativeNavbar from "../components/AlternativeNavbar";

export default function ErrorPage() {
  const error = useRouteError();
  // console.error(error);
  return (
    <div className="">
    <AlternativeNavbar />
    
    <div className="aboutuspagebody">
      <h1 className="aboutustitle">Service Suspended</h1>
      <div className="aboutusparagraphs">
        {/* <p style = {{
          textAlign: 'center',
          fontSize: 20
        }}>
          The Clean Creator Community programme is currently decomissioned and tempoarily unavilable. Do check back later.
        </p> */}
      </div>
    </div>
  </div>
  );
}
