import React from "react";
import { createBrowserRouter } from "react-router-dom";
import "./index.css";
import Homepage from "./pages/Homepage";
import ErrorPage from "./components/error-page";
import AboutUspage from "./pages/AboutUspage";
import Privacynotice from "./pages/Privacynotice";
import Cookienotice from "./pages/Cookienotice";
import Termsandconditions from "./pages/Termsandconditions";
import Termsofuse from "./pages/Termsofuse";
import Faq from "./pages/Faq";


const router = createBrowserRouter([
  {
    path: "/",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "privacy-notice",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "cookie-notice",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "terms-and-conditions",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "terms-of-use",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/faq",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
